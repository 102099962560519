import React from 'react';
import LiquidCalculator from "./components/liquidCalculator";

function App() {
    return (
        <div className="app">
            <LiquidCalculator />
        </div>
    );
}

export default App;
