import React, {useEffect, useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import {makeStyles} from '@material-ui/core/styles';
import FormControlLabel from "@material-ui/core/FormControlLabel";

const LiquidCalculator = () => {
    const [totalLiquid, setTotalLiquid] = useState(true)
    const [liquidValue, setLiquidValue] = useState(0)
    const [aromaPercentage, setAromaPercentage] = useState(0)
    const [aromaValue, setAromaValue] = useState(0)
    const [nicotineShot, setNicotineShot] = useState(20)
    const [nicotineValue, setNicotineValue] = useState(0)
    const [calculatedLiquid, setCalculatedLiquid] = useState(0.00)
    const [calculatedAroma, setCalculatedAroma] = useState(0.00)
    const [calculatedNicotine, setCalculatedNicotine] = useState(0.00)
    const useStyles = makeStyles((theme) => ({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
    }));

    useEffect(() => {
        let currentLiquidValue = liquidValue < 1 && aromaPercentage > 0 ? (aromaValue * 100 / aromaPercentage) : liquidValue;
        let currentAromaValue = currentLiquidValue / 100 * aromaPercentage;
        let currentNicotineValue = currentLiquidValue * nicotineValue / nicotineShot;

        setCalculatedAroma(currentAromaValue);
        setCalculatedNicotine(currentNicotineValue);
        setCalculatedLiquid(currentLiquidValue - currentAromaValue - currentNicotineValue);
    }, [liquidValue, aromaPercentage, nicotineShot, nicotineValue, totalLiquid, aromaValue])

    const classes = useStyles();

    return (
        <>
            <h1>Liquidrechner</h1>
            <div className="container">
                <div className="left">
                    <form className={classes.root} noValidate autoComplete="off">
                        <div>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={totalLiquid}
                                        onChange={(event) => setTotalLiquid(event.target.checked)}
                                        name="test"
                                        color="primary"
                                    />
                                }
                                label="Liquid Menge total"
                            />
                        </div>
                        <div>
                            <TextField
                                required
                                label="Liquid Menge in ml"
                                value={liquidValue}
                                onChange={(event => setLiquidValue(event.target.value))}
                            />
                        </div>
                        <div>
                            <TextField
                                required
                                label="Aroma Anteil in %"
                                value={aromaPercentage}
                                onChange={(event => setAromaPercentage(event.target.value))}
                            />
                        </div>
                        <div>
                            <TextField
                                required
                                label="Aroma Anteil in ml"
                                value={aromaValue}
                                onChange={(event => setAromaValue(event.target.value))}
                            />
                        </div>
                        <div>
                            <TextField
                                required
                                label="Nikotinstärke Nikotinshot"
                                value={nicotineShot}
                                onChange={(event => setNicotineShot(event.target.value))}
                            />
                        </div>
                        <div>
                            <TextField
                                required
                                label="Gewünschte Nikotinstärke"
                                value={nicotineValue}
                                onChange={(event => setNicotineValue(event.target.value))}
                            />
                        </div>
                    </form>
                </div>
                <div className="right">
                    <div>Aroma: {calculatedAroma}ml</div>
                    <div>Nikotin: {calculatedNicotine}ml</div>
                    <div>Base: {calculatedLiquid}ml</div>
                    <div>Total: {calculatedAroma + calculatedNicotine + calculatedLiquid}ml</div>
                </div>
            </div>
        </>
    );
}

export default LiquidCalculator;
